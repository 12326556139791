import React, { useContext, useEffect, useState } from "react"
import FilterSearchBlock from "./FilterSearchBlock";

const StoreLsidebar = ({rangePrice, setRangePrice, sortingProducts, query, setQuery, selectedCat, setSelectedCat, selectedBrand, setSelectedBrand, maxPrice}) => {

    return (
       <>
        <div className="store-lsidebar">
            <div className="base-lsidebar-col">
                <FilterSearchBlock rangePrice={rangePrice} setRangePrice={setRangePrice} sortingProducts={sortingProducts} query={query} setQuery={setQuery} selectedCat={selectedCat} setSelectedCat={setSelectedCat} selectedBrand={selectedBrand} setSelectedBrand={setSelectedBrand} maxPrice={maxPrice}/>
            </div>
        </div>
       </>
    )
}

export default StoreLsidebar;