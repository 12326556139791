import React, { useContext, useEffect, useState } from "react"
import { Splide, SplideTrack, SplideSlide} from '@splidejs/react-splide'
import img_pack from "../img/img";
import { Context } from "../App";
import {products, cats, posts, StorePageRoot, egoda_url, WhyPageRoot} from "../utils/consts";
import ProductExample from "./components/Product_example";
import { useFetching } from "../hooks/UseFetching";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ProductsCatNav from "./components/ProductsCatNav";
import GetMain from "../API/GetMain";
import Map2gis from "./components/Map2gis";

const Main = () => {

    const {windowWidth} = useContext(Context)
    const [productsIphone, setProductsIphone] = useState([])
    const [productsMac, setProductsMac] = useState([])
    const [selectedProductClass, setSelectedProductClass] = useState('Все')
    const [selectedMacClass, setSelectedMacClass] = useState('Все')

    const navigate = useNavigate()
    const location = useLocation()
    
    // // Получение всех товаров
    const [GetMainProducts1] = useFetching( async (productData, page) => {
        const res = await GetMain.GetMainProducts(productData, page)
        // console.log('GetMainProducts complete:' , res.data)
        setProductsIphone(res.data.result)
    })
    const [GetMainProducts2] = useFetching( async (productData, page) => {
        const res = await GetMain.GetMainProducts(productData, page)
        // console.log('GetMainProducts complete:' , res.data)
        setProductsMac(res.data.result)
    })

    useEffect(() => {
        const formdata = new FormData();
        formdata.append("category", 'iphone-16');
        formdata.append("item_quantity", 10);
        formdata.append("is_popular", 0);
        formdata.append("model", 
        (selectedProductClass === 'Все' ? '' : 
        selectedProductClass === 'Pro' ? 'Apple iPhone 16 Pro' : 
        selectedProductClass === 'Pro Max' ? 'Apple iPhone 16 Pro Max' : ''));
        GetMainProducts1(formdata, 1)
    },[selectedProductClass])

    useEffect(() => {
        const formdata = new FormData();
        formdata.append("category", 'macbook');
        formdata.append("item_quantity", 10);
        formdata.append("is_popular", 0);
        formdata.append("model_proc", 
        (selectedMacClass === 'Все' ? '' : 
        selectedMacClass === 'M1' ? 'Apple M1' : 
        selectedMacClass === 'M2' ? 'Apple M2' : 
        selectedMacClass === 'M3' ? 'Apple M3' : ''));
        GetMainProducts2(formdata, 1)
    },[selectedMacClass])

    return (
    <>
        <section className="offer-block" style={{background: 'linear-gradient(270deg, #CFCFCF 0%, rgba(207, 207, 207, 0.305882) 49.5%, #CFCFCF 100%)'}}>
            <div className="bg-cont-1"></div>
            <div className="container">
                <div className="col-10">
                    <span className="h-36-500-i">Новый iPhone 16</span>
                    <span className="h-16-400-i">Закажи уже сегодня</span>
                    <div style={{display: 'flex'}}><Link to={StorePageRoot} className="base-btn white h-14-400-j">Выбрать свой</Link></div>
                </div>
            </div>
        </section>
        <section className="block-base"> 
            <div className="container">
                <div className="block-base-content">
                    <div className="main-info-row">
                        <div className="main-info-block">
                            <div>
                                <span className="h-18-400-j" style={{lineHeight: '24px'}}>Отзывы наших покупателей</span>
                                <div><Link to={'https://m.avito.ru/brands/i198132908'} target="_blank" className="h-14-400-j">Смотреть отзывы</Link></div>
                            </div>
                            <div><img src={img_pack.main_infoBlock_avito} alt="img" /></div>
                        </div>
                        <div className="main-info-block">
                            <div>
                                <span className="h-18-400-j" style={{lineHeight: '24px'}}>Нужна консультация по технике?</span>
                                <div><Link to={'/contact_po'} state={{prevLocation: location}} className="h-14-400-j">Задать вопрос</Link></div>
                            </div>
                            <div><img src={img_pack.main_infoBlock_clock} alt="img" /></div>
                        </div>
                        <div className="main-info-block">
                            <div>
                                <span className="h-18-400-j" style={{lineHeight: '24px'}}>Скидка на технику под заказ</span>
                                <div><Link to={`${WhyPageRoot}`} className="h-14-400-j">Почему так?</Link></div>
                            </div>
                            <div><img src={img_pack.main_infoBlock_headphones} alt="img" /></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="block-base"> 
            <div className="container">
                <div className="block-base-content">
                    <div className="products-title">
                        <div className="h-26-400-j gr8">Новый iPhone 16</div>
                        <ProductsCatNav catNav={['Pro','Pro Max']} activeItems={selectedProductClass} setActiveItems={setSelectedProductClass}/>
                    </div>
                    <div className="products-block">
                        {productsIphone?.map((el) => (
                            <ProductExample key={el.id} res={el}></ProductExample>
                        ))}
                    </div>
                    {windowWidth <= 600 &&
                        <button className="viewAll-btn" style={{maxWidth: '80%', width: '100%', margin: '0 auto'}} onClick={() => navigate(`${StorePageRoot}`)}>Посмотреть все</button>
                    }
                </div>
            </div>
        </section>
        <section className="block-base"> 
            <div className="container"> 
                <div className="block-base-content">
                    <div className="h-26-400-j c-2">Почему лучше покупать у нас?</div>
                    <div className="prv-block-row">
                        <div className="prv-block-ex">
                            <div className="prv-block-img">
                                <img src={img_pack.prv_dollar} alt="ic" />
                            </div>
                            <div className="h-18-600-i">Самые низкие цены</div>
                            <div className="h-16-400-i">У нас самые низкие цены на технику Apple и полностью оригинальная продукция</div>
                        </div>
                        <div className="prv-block-ex">
                            <div className="prv-block-img">
                                <img src={img_pack.prv_truck} alt="ic" />
                            </div>
                            <div className="h-18-600-i">Доставка по Челябинску</div>
                            <div className="h-16-400-i">Доставим в удобное время и место.</div>
                        </div>
                        <div className="prv-block-ex">
                            <div className="prv-block-img">
                                <img src={img_pack.prv_map} alt="ic" />
                            </div>
                            <div className="h-18-600-i">Доставка ТК во все регионы РФ</div>
                            <div className="h-16-400-i">Быстрые сроки доставки и удобное получение</div>
                        </div>
                        <div className="prv-block-ex">
                            <div className="prv-block-img">
                                <img src={img_pack.prv_card} alt="ic" />
                            </div>
                            <div className="h-18-600-i">Различные способы оплаты</div>
                            <div className="h-16-400-i">Оплатите удобным способом</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="block-base"> 
            <div className="container">
                <div className="block-base-content">
                    <div className="products-title">
                        <div className="h-26-400-j gr8">Macbook</div>
                        <ProductsCatNav catNav={['M1','M2','M3']} activeItems={selectedMacClass} setActiveItems={setSelectedMacClass}/>
                    </div>
                    <div className="products-block">
                        {productsMac?.map((el) => (
                            <ProductExample key={el.id} res={el}></ProductExample>
                        ))}
                    </div>
                    {windowWidth <= 600 &&
                        <button className="viewAll-btn" style={{maxWidth: '80%', width: '100%', margin: '0 auto'}} onClick={() => navigate(`${StorePageRoot}`)}>Посмотреть все</button>
                    }
                </div>
            </div>
        </section>
        <section className="block-base"> 
            <div className="container"> 
                <div className="block-base-content">
                    <div className="contacts-block">
                        <div className="contact-info">
                            <div className="h-26-400-j c-2">Контакты</div>
                            <div className="contacts-row">
                                <div className="contact-ex">
                                    {/* <div className="col">
                                        <a href="tel:+79518046364" className="h-24-600-i">+7 (951) 804-63-64</a>
                                        <a href="tel:+79956805058" className="h-24-600-i">+7 (995) 680-50-58</a>
                                    </div> */}
                                    <span className="h-16-400-i">пн-пт: 10:00-19:00<br/>сб: 10:00-18:00<br/>вс: 10:00-17:00</span>
                                    <Link to={'/contact_po'} state={{prevLocation: location}} className="call_btn h-14-400-j">Заказать звонок</Link>
                                </div>
                                <div className="contact-ex">
                                    <a href="mailto:manager@prostore-protechno.ru" className="h-24-600-i">manager@prostore-protechno.ru</a>
                                    <span className="h-16-400-i gr5">Напишите нам</span>
                                </div>
                                <div className="contact-ex">
                                    <div className="row-15-a">
                                        <img src={`${img_pack.contact_mappinIcon}`} alt="ic" />
                                        <span className="h-16-400-i">Офис</span>
                                    </div>
                                    <span className="h-16-400-i">Челябинск, 454080, Центральный район, Свердловский проспект, 62</span>
                                    <div className="row-15">
                                        <Link to={'https://m.avito.ru/brands/i198132908'} target="_blank"><img src={`${img_pack.contact_avitoIcon}`} alt="ic" style={{minWidth: '32px', maxWidth: '32px'}}/></Link>
                                        <Link to={'https://t.me/protechno_shop'} target="_blank"><img src={`${img_pack.contact_telegramIcon}`} alt="ic" style={{minWidth: '32px', maxWidth: '32px'}}/></Link>
                                        <Link to={'https://wa.me/79518046364'} target="_blank"><img src={`${img_pack.contact_whatsappIcon}`} alt="ic" style={{minWidth: '32px', maxWidth: '32px'}}/></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Map2gis></Map2gis>
    </>
    )
}

export default Main;